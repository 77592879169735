.backgroundImg {
  position: absolute;
  z-index: 1;
  /* background: rgba(76, 175, 80, 0.3) url("./frente_noche_mobile.jpg"); */
  background: rgba(76, 175, 80, 0.3) url("./frente_noche.jpg");
  background-size: 100%;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 600px) {
  .backgroundImg {
    position: absolute;
    z-index: 1;
    height: 100vh;
    background: rgba(76, 175, 80, 0.3) url("./frente_noche_mobile.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.logoBanner {
  width: 250px;
}
