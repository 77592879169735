.navbarCustom {
  background: transparent;
}

.nav-link {
  color: #fff;
  text-decoration: none;
}

.nav-link:hover {
  color: beige;
}
